import * as React from 'react'
import Layout from '../components/layout'
import NotFound from '../../static/assets/svgs/error.svg'

const NotFoundPage = () => {
  return (
    <Layout>
      <div className='py-0  my-32 bg-white'>
        <div className='max-w-2xl mx-auto px-4 sm:px-6 lg:px-8'>
          <NotFound />
          <p className='mt-3 text-center max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl'>
            Ups! It seems like the page you are looking for is not available!
            Please try again with another page or go back to home.
          </p>
          <div className='mt-3 rounded-md shadow sm:mt-0 sm:ml-3'>
            <a
              href='/'
              className='w-full flex items-center justify-center px-8 py-3 border border-customBlue text-base font-medium rounded-md text-customBlue bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10'>
              Go to home page
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
